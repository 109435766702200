// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
      if ($('.circle').hasClass('active')){
        TweenMax.to($('.circle-md'), 1, {
          rotationZ : 150  ,

        });
        TweenMax.to($('.circle-sm'), 1, {
          rotationZ :220  ,

        });
      }
      if ($element.hasClass('single-circle')){
        if ($element.hasClass('launch-animation')){

        }
        else {
            TweenMax.staggerFromTo($element, 1, { scale: 0, rotation: 0 }, { scale: 1, opacity: 1, rotation: 0, ease: Back.easeInOut }, 4);
            $element.addClass('launch-animation');
        }
      }
    }
    //else {
      //$element.removeClass('active');
    //}
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

     $('.js-replace-img').each(function() {

       var src = $(this).find("img").attr("src");
       $(this).css("background-image","url("+src+")");
     });
    };

    jQuery(function() {
     if($(".js-replace-img")) {
       replaceSrc();
     }
    });

//
jQuery('.navigation-tris-nav').click(function(e) {
  jQuery('.navigation-tris').toggleClass('active');
  jQuery(this).toggleClass('active');
});

(function(){

  var s = {

    menuState: function() {

      $(this).toggleClass("cross");
      $(".main-nav-wrapper").toggleClass("open");

    },

    headerHeight: function() {

      if($(window).scrollTop() > 0) {

        return $('.main-header').height();
      } else {

        return $('.main-header').height() - 24;
      }
    },

    headerPosition: function() {

      var top = $(window).scrollTop();

      if(top > 72) {

        $(".main-header").addClass("min");
        $(".sub-nav").addClass("min");
      } else {

        $(".main-header").removeClass("min");
        $(".sub-nav").removeClass("min");
      }
    },

    events: function() {
      $(window).scroll(this.headerPosition);
      $("#btnMobile").on("click",this.menuState);
    },

    init: function() {

      this.headerPosition();
      this.events();
    }

  }

  s.init();

})();


//PARALLAX DOT CONTAINER
$(".dot-container").mousemove(function(e) {
  parallaxIt(e, ".circle-md", -10);
  parallaxIt(e, ".circle-sm", -20);
  parallaxIt(e, ".single-circle-1", -50);
  parallaxIt(e, ".single-circle-2", -100);
  parallaxIt(e, ".single-circle-3", -100);
  parallaxIt(e, ".single-circle-4", -200);


});

//CREAT TWIN TO RANDOM SET CIRCLE

TweenMax.to($('.circle-md'), 1, {
  rotationZ : 150  ,

});
TweenMax.to($('.circle-sm'), 1, {
  rotationZ :200  ,

});
TweenMax.to($('.circle-md:after'), 1, {
  //rotationZ :-randomCirlce1  ,

});
TweenMax.to($('.circle-md:before'), 1, {
  //rotationZ :-randomCirlce1 ,

});


$(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    // Do something
    var $window = $(window);
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);
    var $element_to_paralax = $(".dot-container");
    $.each($element_to_paralax, function(index) {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = $element.offset().bottom;

    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport

    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
        parallaxItBis($element,window_top_position, $element.find(".circle-md"), -10);
        parallaxItBis($element,window_top_position, $element.find(".circle-sm"), -20);
        parallaxItBis($element,window_top_position, $element.find(".single-circle-1"), -50);
        parallaxItBis($element,window_top_position, $element.find(".single-circle-2"), -100);
        parallaxItBis($element,window_top_position, $element.find(".single-circle-3"), -100);
        parallaxItBis($element,window_top_position, $element.find(".single-circle-4"), -200);

    }
  });

});



function parallaxItBis($element,e, target, movement) {
  var $this = $element;
  var relY = e - $this.offset().top;
  TweenMax.to(target, 1, {
    y: (relY - $this.height() / 2) / $this.height() * movement,

  });

}

//here you pass the filter to the DOM element

function parallaxIt(e, target, movement) {
  var $this = $(".dot-container");
  var relX = e.pageX - $this.offset().left;

  TweenMax.to(target, 1, {
    x: (relX - $this.width() / 2) / $this.width() * movement
  });
}

///////////////
//////////////
/////////////
////////////
///////////
//////////
/////////
////////
///////
//////
/////
////
///
//

// PARALLAX FUNCTION AUTOMATISATION
function parallax(){

  $(window).scroll(function (event) {
      var scroll = $(window).scrollTop();
      // Do something
      var $window = $(window);
      var window_height = $window.height();
      var window_top_position = $window.scrollTop();
      var window_bottom_position = (window_top_position + window_height);
      var $element_to_paralax = $(".parallax-container");
      $.each($element_to_paralax, function() {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = $element.offset().bottom;

      var element_bottom_position = (element_top_position + element_height);

      //check to see if this current container is within viewport

      if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
          $.each($('.parallax-item'),function(){
            $(this).addClass('parallax-it');
            parallaxItBis(window_top_position, $(this), $(this).attr('data-velocity'));
          })


      }
    });

  });


}
parallax();


/*
* 1)load_ajax_content (send url of ajax and the json_encode($queryAgrs))
* 2) Implementat page number each time you click on the show more button
* 3) Add desible status to button until it load the content
* 4) On succes happend data to ".load-ajax" don't forget to put this class to the container
*    Set button disabled false to re-active the dynamic load content
*    add animation on tempalte element
*    Reload function replaceSrc;
*/
function load_ajax_content(ajaxUrl,query,intranet){
  query.paged += 1;


      jQuery("#more_posts").attr("disabled",true); // Disable the button, temp.
      jQuery('.loader-gif').addClass('show');
      jQuery.post(ajaxUrl, {
          action:"more_post_ajax",
          query : query,
          intranet : intranet,
      }).success(function(posts){
/* On succes happend data to ".load-ajax" don't forget to put this class to the container
*  Set button disabled false to re-active the dynamic load content
*  Remove animation on tempalte element
*  Reload function replaceSrc;
*/
          if ((query.paged+1) > $("#more_posts").attr("data-page-total") ){
            $('.pagination').remove();
          }
          jQuery(".load-ajax").append(posts);
          jQuery("#more_posts").attr("disabled",false);
          jQuery('.loader-gif').removeClass('show');

          setTimeout(function () {
            var $animation_elements = $('.animated');
            $animation_elements.addClass('active');
          }, 10);

          jQuery(function() {
           if($(".js-replace-img")) {
             replaceSrc();
           }
          });
      });

}
function filter_ajax_content(ajaxUrl,query,type,otherVal1,otherVal2,search){

  query = [query,type,otherVal1,otherVal2,search];
  $('.project-section').addClass('waiting-result');
      jQuery.post(ajaxUrl, {
          action:"filter_post_ajax",
          query : query,
      }).success(function(posts){
          $('.project-item').remove();
          $('.project-section').append(posts);
          $('.project-section').removeClass('waiting-result');

          setTimeout(function () {
            var $animation_elements = $('.animated');
            $animation_elements.addClass('active');
          }, 10);
          jQuery(function() {
           if($(".js-replace-img")) {
             replaceSrc();
           }
          });


      });

}


function load_ajax_content_special(ajaxUrl,paged){

      jQuery("#more_posts").attr("disabled",true); // Disable the button, temp.
      jQuery('.loader-gif').addClass('show');
      jQuery.post(ajaxUrl, {
          action:"more_post_ajax_special",
          paged : paged,
      }).success(function(posts){
/* On succes happend data to ".load-ajax" don't forget to put this class to the container
*  Set button disabled false to re-active the dynamic load content
*  Remove animation on tempalte element
*  Reload function replaceSrc;
*/
          if ((paged) > $("#more_posts").attr("data-page-total") ){
            $('.pagination').remove();
          }
          jQuery(".load-ajax").append(posts);
          jQuery("#more_posts").attr("disabled",false);
          jQuery('.loader-gif').removeClass('show');

          setTimeout(function () {
            var $animation_elements = $('.animated');
            $animation_elements.addClass('active');
          }, 10);

          jQuery(function() {
           if($(".js-replace-img")) {
             replaceSrc();
           }
          });
      });

}

$('.navigation-tris-nav').click(function(){
  $('.navigation-tris').addClass('active');
})

$('.cross').click(function(){
  $('.navigation-tris').removeClass('active');
})

var cntreq = 0;
var cntvals = 0;
  $('#acf-user-form input:enabled:not(disabled,[type=hidden],[type=checkbox],[type=submit],#acf-field_5b7e7ed0953b9-field_5b7e8d32f0cf0-acfcloneindex-field_5b7e8d4ff0cf1,#acf-field_5b7e7ed0953b9-field_5b7e8d32f0cf0-acfcloneindex-field_5b7e8e0bf0cf3,#acf-field_5b7e7ed0953b9-field_5b7e8d32f0cf0-acfcloneindex-field_5b7e8dd0f0cf2,#acf-field_5b7e7ed0953b9-field_5b7e8fdd4a2b4-acfcloneindex-field_5b7e90214a2b7,#acf-field_5b7e7ed0953b9-field_5b7e8fdd4a2b4-acfcloneindex-field_5b7e900a4a2b6,#acf-field_5b7e7ed0953b9-field_5b7e8fdd4a2b4-acfcloneindex-field_5b7e8ff44a2b5,#acf-field_5b7e7ed0953b9-field_5b8646cbf2cb2-acfcloneindex-field_5b86471df2cb3,#acf-field_5b7e7ed0953b9-field_5b7e919c0913d-acfcloneindex-field_5b7e91e70913f,#acf-field_5b7e7ed0953b9-field_5b7e919c0913d-acfcloneindex-field_5b7e91c40913e,#acf-_validate_email,.ed_button,.input)').each(function(i, val) {
  cntreq++;
    if($(this).val().length != 0) {
      cntvals++;

    }
  });
var count = (cntvals/cntreq)*100;

if (Math.round(count) > 0 ){
  $('#percentage').empty();
  $('#percentage').append(Math.round(count)+'% completed');
  $('#percentage_bar').width( (Math.round(count)+'% ') );
}

  $('#acf-user-form').on('change', function(){

        var cntreq = 0;
        var cntvals = 0;
        $('#acf-user-form input:enabled:not(disabled,[type=hidden],[type=checkbox],[type=submit],#acf-field_5b7e7ed0953b9-field_5b7e8d32f0cf0-acfcloneindex-field_5b7e8d4ff0cf1,#acf-field_5b7e7ed0953b9-field_5b7e8d32f0cf0-acfcloneindex-field_5b7e8e0bf0cf3,#acf-field_5b7e7ed0953b9-field_5b7e8d32f0cf0-acfcloneindex-field_5b7e8dd0f0cf2,#acf-field_5b7e7ed0953b9-field_5b7e8fdd4a2b4-acfcloneindex-field_5b7e90214a2b7,#acf-field_5b7e7ed0953b9-field_5b7e8fdd4a2b4-acfcloneindex-field_5b7e900a4a2b6,#acf-field_5b7e7ed0953b9-field_5b7e8fdd4a2b4-acfcloneindex-field_5b7e8ff44a2b5,#acf-field_5b7e7ed0953b9-field_5b8646cbf2cb2-acfcloneindex-field_5b86471df2cb3,#acf-field_5b7e7ed0953b9-field_5b7e919c0913d-acfcloneindex-field_5b7e91e70913f,#acf-field_5b7e7ed0953b9-field_5b7e919c0913d-acfcloneindex-field_5b7e91c40913e,#acf-_validate_email,.ed_button,.input)').each(function(i, val) {

                cntreq++;
                if($(this).val().length != 0) {
                    cntvals++;
              }
        });
        var count = (cntvals/cntreq)*100;
        $('#percentage').empty();
        $('#percentage').append(Math.round(count)+'% completed');
        $('#percentage_bar').width( (Math.round(count)+'% ') );

    });
