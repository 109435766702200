(function() {
  var c = {

    carrouselInit: function() {

      $(".js-carrousel").each(function() {

        var slideshow = $(this);
        slideshow.attr("data-current",0);
        var type = slideshow.attr("data-type");
        var loop = slideshow.attr("data-loop");
        var effect = slideshow.attr("data-effect");
        var wrapper = slideshow.find(".js-carrousel-wrapper");
        var slides = slideshow.find(".js-carrousel-slide");
        var controller = slideshow.find(".js-carrousel-controllers");
        var cType = controller.attr("data-type");

        c.slidesInit(wrapper,slides,type,loop,effect);
        c.controllerInit(slideshow,controller,cType);
      });

      if($('.js-carrousel-modal')) {

        $(".js-carrousel-modal").each(function() {
          var t = $(this);
          var close = $("<button>close</button>").addClass("js-carrousel-close");
          close.click(function() {t.removeClass("show")});
          $(this).append(close);
        });
      }
    },

    controllerInit: function(slideshow,controller,type) {

      if(type == "switch") {

        var next = $("<span></span>").addClass("js-carrousel-next").text("next");
        var prev = $("<span></span>").addClass("js-carrousel-prev").text("previous");
        controller.append(next,prev);
        $(next).on("click",function() {c.switching(slideshow,'next');});
        $(prev).on("click",function() {c.switching(slideshow,'prev');});
      }
    },

    slidesInit: function(wrapper,slides,type,loop,effect) {

      slides.each(function(i) {

        var img = $(this).find("img");
        var imgPath = img.attr("src");

        if(effect == "zoom") {

          var span = $("<span></span>");
          span.css("background-image","url("+imgPath+")");
          $(this).find(".js-carrousel-image").append(span);

        } else {

          $(this).find(".js-carrousel-image").css("background-image","url("+imgPath+")");
        }

        if(type === "multiple") {

          var k = 100*i;
          $(this).css("transform",'translate('+ k +'%,0)');
        }
      });

      if(loop) {

        var first = slides.eq(0).clone();
        var last = slides.eq(-1).clone();
        first.css("transform",'translate('+ slides.length * 100 +'%,0)');
        last.css("transform",'translate('+ -100 +'%,0)');
        wrapper.prepend(last);
        wrapper.append(first);
      }
    },

    updatePosition: function(slideshow) {

      var k = slideshow.attr('data-current');
      var slides = slideshow.find(".js-carrousel-slide");

      slides.each(function(i) {

        $(this).css("transform","translate("+ ((i*100) - 100) +"%,0)");
      });
    },

    switching: function(slideshow,d) {

      var current = parseInt(slideshow.attr('data-current'));
      var slides = slideshow.find(".js-carrousel-slide");
      var wrapper = slideshow.find(".js-carrousel-wrapper");
      var k;
      if(d === "next") {

        k = -1;

        var first = slides.eq(2).clone();
        slides.eq(0).remove();
        first.css("transform",'translate('+ slides.length * 100 +'%,0)');
        wrapper.append(first);

      } else {

        k = 1;

        var last = slides.eq(-3).clone();
        slides.eq(-1).remove();
        last.css("transform",'translate('+ -100 +'%,0)');
        wrapper.prepend(last);
      }

      current += k;
      slideshow.attr("data-current",current);

      c.updatePosition(slideshow);
    },

    events: function() {

      if($(".js-carrousel-open")) {

        $(".js-carrousel-open").click(function() {
          var carrousel = $(this).attr("data-carrousel");
          $(".js-carrousel-modal").each(function(){
            if( $(this).attr("data-carrousel") == carrousel) {

              $(this).addClass("show");
            }
          });
        });
      }
    },

    init: function() {

      this.events();
      this.carrouselInit();
    }
  };

  c.init();
})();
